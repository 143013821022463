import React, { useEffect, useRef, useState } from 'react';
import { YouTubePlayer } from "@app/landing-page/social-media-embeds";
import { LicenseInfoSection } from "@common/billing/pricing-table/license-info";
import { ContactSection } from "@common/billing/pricing-table/pricing-layout-page";
import { LayoutPricing } from "@common/billing/pricing-table/pricing-layout-table";
import { CheckIcon } from '@heroicons/react/20/solid';
import { Navbar } from '@common/ui/navigation/navbar/navbar';
import { NavbarNavigation } from "@app/web-player/layout/navbar-navigation";
import { Footer } from "@app/web-player/layout/footer";
import { useProducts } from '@common/billing/pricing-table/use-products';
import { useAuth } from '@common/auth/use-auth';
import { HomePageCompanies } from '@app/landing-page/landing-page';
import {
    findBestPrice,
    UpsellBillingCycle,
} from '@common/billing/pricing-table/find-best-price';



import { AudiotrackIcon } from '@common/icons/material/Audiotrack';
import { FeedbackIcon } from '@common/icons/material/Feedback';
import { AutoAwesomeIcon } from '@common/icons/material/AutoAwesome';
import { GroupsIcon } from '@common/icons/material/Groups';
import { MicIcon } from '@common/icons/material/Mic';
import { SupportIcon } from '@common/icons/material/Support';
import { TextSnippetIcon } from '@common/icons/material/TextSnippet';
import { Link } from 'react-router-dom';

import pricing from './pricing-page-images/pricing.png'
import { ProgressCircle } from '@common/ui/progress/progress-circle';
import { JSX } from 'react/jsx-runtime';
import { CampaignIcon } from '@common/icons/material/Campaign';
import { TrustpilotCarousel } from './components/trustpilot-carousel';

export function FreeTrialIntroSection({ onPlansClick } : { onPlansClick : any }) {

    return (
        <div className="bg-gradient-to-b from-danger/70 to-black/0">
            <div className='mx-auto max-w-2xl lg:max-w-6xl'>
                <div className='pt-0 lg:pt-48'/>
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-x-24 gap-y-24 pb-6 xxl:pb-36 pt-48 md:pt-48 px-24">
                    <div className='w-full'>
                        <div className='text-4xl md:text-6xl font-semibold md:font-bold text-center '>
                            Get beats & artist tools every month with a Beatsora subscription!
                        </div>
                        <div className='text-2xl md:text-3xl pt-20 font-semibold text-center '>
                        Save 25% with your first two months using code <b className='text-danger'>SAVE25!</b>
                        </div>
                        {/**
                        <div className='text-xl md:text-2xl pt-12 pb-12 font-bold text-center lg:text-left'>
                            First beat is on us!
                        </div>
                         */}
                         {/**
                        <div className="pt-12 flex flex-col xss:flex-row items-center gap-x-6 mx-auto max-w-md">
                            <button
                                onClick={onPlansClick}
                                className="rounded-md max-w-[150px] xxs:max-w-none bg-danger px-10 py-6 text-lg font-semibold text-white shadow-sm hover:bg-dangerlighter focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 mb-4 xss:mb-0 mx-auto"
                            >
                                View all plans
                            </button>
                            
                        </div>
                        */}

                        <div className='pt-12 text-xs text-white text-center pb-12 lg:pb-0 mx-auto'>
                        Offer available on first subscription only. <a target="_blank" className="text-danger font-semibold" href='https://app.termly.io/policy-viewer/policy.html?policyUUID=e2595200-267e-4272-9008-c22ffc0ab4a3'>Terms apply</a>.
                        </div>
                    </div>
                    {/**
                    <div className="hidden lg:block mx-auto">
                        <img
                            alt=""
                            src={pricing}
                            className="max-w-md h-auto object-cover object-center mx-auto"
                            />
                    </div>
                     */}
                </div>
            </div>
        </div>
    )
}

const subscriptionFeatures = [
    {
        id: 1,
        name: "Beats",
        icon: AudiotrackIcon,
        href: "/explore",
    },
    {
        id: 2,
        name: "AI Mastering",
        icon: AutoAwesomeIcon,
        href: "/create-aimastering",
    },
    {
        id: 3,
        name: "AI Lyric Generator",
        icon: TextSnippetIcon,
        href: "/ai-lyrics",
    },
    {
        id: 4,
        name: "Track Feedback",
        icon: FeedbackIcon,
        href: "/feedback-form",
    },
    {
        id: 5,
        name: "Vocal Presets",
        icon: MicIcon,
        href: "/vocal-presets",
    },
    {
        id: 6,
        name: "Promotion",
        icon: CampaignIcon,
        href: "/promotion",
    },
  
  
  ] 

export function SubscriptionFeatures({features, title} : {features: any, title: string}) {
    return (
        <>
            <div className="pt-8 sm:pt-20 max-w-7xl mx-auto">
                <div className="flex flex-col items-center">
                    <div className="text-2xl pt-24 pb-24 text-center mx-auto font-bold tracking-tight text-white sm:text-3xl max-w-xl">
                        {title}
                    </div>


                    <div className='grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-6 gap-4 mx-auto max-w-5xl pb-20 px-5'>
                        {features.map((feature: any) => 
                            <a href={feature.href} target="_blank" className="flex flex-col justify-center items-center bg-white/10 rounded-md h-auto w-full aspect-square">
                                <div className="flex flex-col justify-center items-center px-4 py-4 sm:px-16 sm:py-16">
                                    <feature.icon className='text-danger w-28 h-28 sm:w-50 sm:h-50'/>
                                    <div className="text-sm sm:text-xl font-semibold text-center mt-3 sm:mt-8">{feature.name}</div>
                                </div>
                            </a>
                        )}
                    </div>
                    {/** 
                    <ul
                        role="list"
                        className="text-white text-md sm:text-lg leading-6 mx-auto pb-36"
                    >
                        {subscriptionFeatures.map((feature) => (
                            <li key={feature} className="flex gap-x-4 py-2 items-center">
                                <CheckIcon
                                    className="text-danger h-18 w-15 flex-none"
                                    aria-hidden="true"
                                />
                                {feature}
                            </li>
                        ))}
                    </ul>
                    */}
                </div>
            </div>
        </>
    )
}
export function FreeTrialPageLayout() {
    const layoutPricingRef = useRef(null);
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        // Set a timeout to stop loading after 1 second
        const timer = setTimeout(() => {
            setPageLoading(false);
        }, 200);

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    if (pageLoading) {
        return (
            <div className="flex justify-center items-center mx-auto">
                <ProgressCircle
                    size="lg"
                    isIndeterminate
                />
            </div>
        );
    }
    const handlePlansClick = () => {
        layoutPricingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    return (
        <>
            <div className="main-layout-wrapper">
                <FreeTrialIntroSection onPlansClick={handlePlansClick} />
                <div className="bg-black/0">
                    <SubscriptionFeatures features={subscriptionFeatures} title="What's included in Beatsora?"/>
                    <TrustpilotCarousel/>

                    <div ref={layoutPricingRef} id="layoutPricingSection">
                        <LayoutPricing />
                    </div>
                    <HomePageCompanies bgColour="bg-black/0" marqueeClassname='-z-40'/>
                    <LicenseInfoSection />
                    <ContactSection />
                </div>
            </div>
        </>
    );
    
}
